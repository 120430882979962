<template>
  <div class="scroll-list">
    <LicenseList :licenses="licenses" :loading="loading" v-on:remove="removeLicense"/>
  </div>
</template>

<script>
import { API } from 'aws-amplify'
import * as queries from '@/graphql/queries'
import * as mutations from '@/graphql/mutations'
import LicenseList from '@/components/licenses/LicenseList.vue'

export default {
  data () {
    return {
      res: {},
      loading: true,
      licenses: []
    }
  },
  components: {
    LicenseList
  },
  created () {
    this.list()
  },
  methods: {
    async removeLicense (license) {
      console.log('remove slett lisens', license.id)
      const index = this.licenses.indexOf(license)
      console.log('index', index)
      this.licenses.splice(index, 1) // remove from table
      try {
        await API.graphql({
          query: mutations.deleteLicense,
          variables: {input: {
            id: license.id
          }},
          // authMode: 'AMAZON_COGNITO_USER_POOLS'
        });
      } catch (e) {
          console.log('Sletting feilet', JSON.stringify(e))
          alert('Sletting feilet. Er du tilkoblet internett ?')
          this.licenses.splice(index, 0, license)
      }
    },
   async list () {
     // .log('listing licenses')
     try {
      const result = await API.graphql({
          query: queries.listLicenses,
          variables: {
            limit: 1400
          }
      })
      // const result = await API.graphql(graphqlOperation(queries.listLicenses, {limit: 1400}))
      
      // console.log('lisenser', JSON.stringify(result.data.listLicenses.items[0]))
      this.res = result
      this.data = result.data.listLicenses.items
      this.loading = result.loading
      this.licenses = result.data.listLicenses.items
      console.log('this.licenses ant', this.licenses.length)
     } catch (e) {
       console.log('License listing error:', e.errors[0].message)
       this.res = e
     }
   }
  }
}
</script>

<style>

</style>
