<template>
<v-card>

  <v-card-title>
    <v-card-text>
      

    <v-text-field
      v-model="search"
      append-icon="mdi-magnify"
      label="Søk"
      single-line
      hide-details
    ></v-text-field>
    <span v-if="numberOfLicenses" class="m-8">Totalt {{numberOfLicenses}} lisenser</span>
    <span v-else>laster inn lisenser..</span>
    </v-card-text>
    


  </v-card-title>

  <v-data-table
    :headers="headers"
    :items="licenses"
    :items-per-page="1500"
    :loading="loading"
    :search="search"
    :sort-by="['gyldigFraDato']"
    :sort-desc="[true]"
  >
    <template v-if="res.errors && res.errors.length > 0" v-slot:top>
      <p>Noe gikk galt: {{res.errors[0].message}}</p>
    </template>

    <template v-slot:top>
      <v-row justify="center">
        <v-dialog v-model="dialog" persistent max-width="290">
          <v-card>
            <v-card-title class="headline">Slett lisens</v-card-title>
            <v-card-text>Er du sikker på at du vil slette denne lisensen ?</v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="green darken-1" text @click="dialog = false">Avbryt</v-btn>
              <v-btn color="green darken-1" text @click="removeLicense">Slett</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-row>
    </template>

    <template v-if="loading" v-slot:progress>
      <v-skeleton-loader
        type="table"
        :tile="true"
      ></v-skeleton-loader>
    </template>

    <template v-slot:item.gyldigFraDato="{ item }">
      <span>{{new Date(item.gyldigFraDato).toLocaleString().split(',')[0]}}</span>
    </template>

    <template v-slot:item.aktiv="{ item }">
      <div v-if="item.aktiv">Ja</div>
      <div v-else>Nei</div>
      <!---simple-checkbox v-model="item.aktiv"></v-simple-checkbox-->
    </template>

    <template v-slot:item.action="{ item }">
      <div style="min-width: 70px;">
        <v-tooltip top open-delay="500">
          <template v-slot:activator="{ on }">
            <v-icon
              v-on="on"
              class="mr-4"
              @click="editLicense(item)"
            >
              mdi-ticket-account
            </v-icon>

          </template>
          <span>Rediger lisens</span>
        </v-tooltip>

        <v-icon
          class="mr-0"
          @click="remove(item)"
        >
          mdi-delete
        </v-icon>
      </div>
    </template>

  </v-data-table>
</v-card>
</template>

<!-- BRUK TABLES! -->
<script>
export default {
  props: ["licenses", "loading"],
 name: 'LicenseList',
 data () {
   return {
     res: {},
     dialog: false,
     license: null,
     search: ''
   }
 },
 computed: {
  // hideOnSmallScreens () { return this.$vuetify.breakpoint.xs },
   numberOfLicenses () {
     return this.licenses.length
   },  
  headers () {
    if (this.$vuetify.breakpoint.xs) { //
      return [
       {
         text: 'Eier',
         value: 'eier',
         sortable: false
       },
        {
          // text: 'Handlinger',
          value: 'action',
          sortable: false
        }
       ]
    } else {
      return [
       {
         text: 'Eier',
         value: 'eier'
       },
       {
         text: 'Skolekode',
         value: 'id'
       },
       {
         text: 'Aktiv',
         value: 'aktiv'
       },
       {
         text: 'Gyldig fra',
         value: 'gyldigFraDato'
       },
       {
         text: 'Kontaktperson',
         value: 'kontaktperson'
       },
       {
         text: 'Sted',
         value: 'sted'
       },       
       {
         text: 'E-post',
         value: 'kontaktpersonEpost'
       },
       { text: 'Handlinger', value: 'action', sortable: false }
     ]
    }
  },
  // options () {
  //   return {
  //     sortBy: ["gyldigFraDato"],
  //     sortDesc: true
  //   }
  // }
 },
 methods: {
    async remove (license) { // open dialog and set license to be removed
      this.license = license
      // const index = this.licenses.indexOf(license)
      this.dialog = true
    },
    removeLicense () { // get the license to be removed and close dialog
      this.$emit('remove', this.license)
      this.dialog = false
    },
    editLicense (license) {
      console.log('this', this)
      console.log('update', license.id)
      this.$router.push({ name: 'editLicense', params: { id: license.id }})
    }
 }
}
</script>

<style>

</style>
